<template>
  <div class="add-department">
    <div class="add-department-wrapper">
        <div class="add-department-wrapper-header">
           <h1>הוספת/עריכת מחלקות</h1>
           <div class="exit-btn">
            <i @click="$emit('close')" class="material-icons">close</i>
           </div>
        </div>
        <div class="add-department-wrapper-tool">
            <el-input style="width:49%;" v-model="department_name" placeholder="שם המחלקה" /> 
            <el-button @click="handle_add_department" style="width:49%;" type="success">הוספה</el-button>
        </div>
        <div class="add-department-wrapper-content">
            <table>
                <tr>
                    <th>שם מחלקה</th>
                    <th>פעולה</th>
                </tr>
                <template v-for="dep in departments" :key="dep.uid">
                    <tr v-if="dep && dep.name!='הכל'">
                        <td>{{dep.name}}</td>
                        <td>
                            <i @click="handle_delete_dep(dep)" class="material-icons delete-icon">delete</i>
                        </td>
                    </tr>
                </template>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
import {add_department,delete_department} from '../Scripts/scripts'
import {slide_pop_error,alert,alert_confirm} from '../../../../Methods/Msgs'
import { ref } from '@vue/reactivity'

export default {
    props:['departments'],
    emits:['close'],
    setup(props,{emit}){
        const department_name = ref('')
        const handle_add_department = async() => {
            if(department_name.value){
                const index = props.departments.findIndex(dep=>dep.name==department_name.value)
                if(index!=-1) {
                    slide_pop_error(`המחלקה ${department_name.value} כבר קיימת!`)
                    return
                }
                const new_dep = await add_department(department_name.value)
                props.departments.push(new_dep)
                alert('success','הצלחה',
                `המחלקה ${department_name.value} הוספה בהצלחה`)
                .then(()=>{
                    emit('close')
                })
            }
        }

        const handle_delete_dep = (dep) => {
            alert_confirm(`למחוק את מחלקת ${dep.name}?`)
            .then(async res=>{
                if(res.isConfirmed){
                    await delete_department(dep.uid)
                    const index = props.departments.findIndex(_dep=>_dep.uid==dep.uid)
                    index!=-1 && props.departments.splice(index,1)
                    emit('close')
                }
            })
        }

        return{
            department_name,
            handle_add_department,
            handle_delete_dep,
        }
    }
}
</script>

<style scoped>
    .add-department{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        background: rgba(0, 0, 0, 0.7);
    }
    .add-department-wrapper{
        width: 100%;
        max-width: 500px;
        height: 50%;
        background: #fff;
        color: #333;
        border-radius: 5px;
    }
    .add-department-wrapper-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 5px;
        left: 5px;
        z-index: 16;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        background: crimson;
        border-radius: 50%;
        cursor: pointer;
        user-select: none;
    }
    .add-department-wrapper-tool{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5px;
    }
    .add-department-wrapper-content{
        width: 100%;
        height: calc(100% - 100px);
        overflow: hidden;
        overflow-y: auto;
        padding: 5px;
    }
   
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
    @media only screen and (max-width: 600px){
        table th {
            position: static;
        }
    }

    .delete-icon{
        color: var(--danger);
        cursor: pointer;
        user-select: none;
    }
</style>